<template>
  <div class="archive-application-history">
    <div class="wrapper">
      <div class="title font-md">
        <b>신청서 이력</b>
      </div>
      <div class="content" v-if="!state.history.loaded || state.history.list.length" :class="{skeleton: !state.history.loaded}">
        <table class="table">
          <thead>
          <tr>
            <th class="title">
              <div>일시</div>
            </th>
            <th class="title">
              <div>회원명</div>
            </th>
            <th class="title">
              <div>내용</div>
            </th>
          </tr>
          </thead>
          <tr v-for="a in state.history.list" :key="a.id">
            <td>
              <span>{{ a.createDate }}</span>
            </td>
            <td>
              <span>{{ a.memberName }}</span>
            </td>
            <td>
              <span :class="{pass: a.content.indexOf('통과') >= 0, denied: a.content.indexOf('보완') >= 0, fail: a.content.indexOf('반려') >= 0}">{{ a.content }}</span>
            </td>
          </tr>
        </table>
        <PaginationJpa className="btn-primary" :change="load" :total="state.total" :pageable="state.pageable" v-if="state.pageable.size"/>
      </div>
      <NoData v-else/>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import http from "@/scripts/http";
import NoData from "@/components/NoData.vue";
import PaginationJpa from "@/components/PaginationJpa.vue";
import Anchor from "@/components/Anchor.vue";
import store from "@/scripts/store";

function Component(initialize) {
  this.name = "modalArchiveApplicationHistory";
  this.initialize = initialize;
}

export default defineComponent({
  components: {NoData, PaginationJpa, Anchor},
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      store.commit("tuneModal", {component, size: "sm"});

      load();
    });

    const state = reactive({
      history: {
        loaded: false,
        list: [],
      },
      args: {
        page: 0,
        size: 10,
      },
      pageable: {
        page: 0,
        size: 0,
      },
      total: 0,
    });

    const modalParams = store.getters.modalParams(component);

    const load = (page) => {
      state.history.list = [];
      state.history.loaded = false;
      state.args.page = page || 0;

      for (let i = 0; i < 10; i += 1) {
        state.history.list.push({
          createDate: "0000-00-00 00:00:00",
          content: "Please wait a moment",
          memberName: "Wait",
        });
      }

      http.get(`/api/archives/applications/${modalParams.id}/histories`, state.args).then(({data}) => {
        state.history.loaded = true;
        state.history.list = data.body.content;
        state.pageable = data.body.pageable;
        state.total = data.body.total;
      });
    };

    return {component, state, load};
  }
});
</script>

<style lang="scss" scoped>
.archive-application-history {
  background: #fff;
  padding: $px25;
  min-height: $px162;

  > .wrapper {
    position: relative;

    .title {
      margin-bottom: $px9;
    }

    .desc {
      margin-bottom: $px25;
      color: #5c5c5c;
    }

    .content {
      padding-top: $px5;

      table {
        table-layout: fixed;

        tr {
          .title {
            text-align: center;
          }

          td {
            border: 0;
            border-top: $px1 solid #eee;
            padding-left: $px5;
            padding-right: $px5;
            padding-top: $px20;
            text-align: center;

            &.date {
              div {
                width: $px90;
                color: #666;
              }
            }

            .pass {
              color: $colorSuccess;
            }

            .denied {
              color: $colorWarning;
            }

            .fail {
              color: $colorDanger;
            }
          }

          &:first-child {
            td {
              border-top: 0
            }
          }
        }
      }

      &.skeleton {
        span {
          @include skeleton;
        }
      }
    }
  }
}
</style>